import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BoutonAcheterTirageAvecPrix from "../../../../composants/boutique/BoutonAcheterTirageAvecPrix"
import Image from "../../../../composants/image/Image"
import LayoutGalerieImageInteractive from "../../../../composants/layout/LayoutGalerieImageInteractive"
import OpenSeaDragonViewer from "../../../../composants/image/OpenSeaDragonViewer"

import {
  obtenirPage,
  obtenirImages,
  obtenirImage,
} from "../../../../js/client-es/utils"

import fragmentsPages from "../../../../js/client-es/fragments/fragments-pages"
import fragmentsImages from "../../../../js/client-es/fragments/fragments-images"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(filter: { code: { eq: "orionZoomEN" } }) {
      ...FragmentPageYaml
    }
    imageAVendre: allImagesCompilationYaml(
      filter: { code: { in: ["orion"] } }
    ) {
      nodes {
        ...FragmentDonneesImages
      }
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "orionZoomAnnotee"
            "voieLacteeOrion"
            "haciendaDesEtoiles"
            "astroPixelProcessorOrion"
            "sgpOrion"
          ]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["doubleSetup1"] } }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesPortraitMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["setupLaNuit"] } }
    ) {
      ...FragmentImagePortraitMoyen
    }
    imagesPortraitPetit: allImagesCompilationYaml(
      filter: { code: { in: ["doubleSetup2"] } }
    ) {
      ...FragmentImagePortraitPetit
    }
  }
`

export default function Orion360(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")
  const images = obtenirImages(
    resultatsRequete,
    "imageAVendre",
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPortraitMoyen",
    "imagesPortraitPetit"
  )

  const image = {
    xmlns: "http://schemas.microsoft.com/deepzoom/2009",
    Format: "jpg",
    Overlap: "1",
    Size: { Width: "18146", Height: "10643" },
    TileSize: "256",
    Url: "/ressources/deep-zoom/orion/tuiles/",
  }

  const composantImageInteractive = <OpenSeaDragonViewer image={image} />

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <h2 className="section-titre">Foreword</h2>
        <p>
          This <strong>360 mega pixels</strong> image, 24 tiles for {""}
          <strong>76 hours of exposure</strong> covers almost the entire the
          constellation of Orion, one of the richest regions of the night sky
          and about 1,500 light years away from us. Visit this beautiful region
          of the sky, rich in golden stars, dark and colorful nebulae.
        </p>
        <div id="galerie-image-interactive-conteneur-achat-tirage-avec-prix">
          <BoutonAcheterTirageAvecPrix
            image={obtenirImage(images, "orion")}
            langue={page.langue}
          />
        </div>
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">Annotated version</h2>
        <p>
          27 objects have been identified in the image below. Click on it to
          open a <strong>annotated zoomable version</strong>:
        </p>
        <Image
          image={obtenirImage(images, "orionZoomAnnotee")}
          langue={page.langue}
          afficherTitre={false}
        />
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">Situation</h2>
        <p>
          The Orion constellation is found in the same name arm of the Milky
          Way, in which our Solar System is found. It is located in periphery of
          our Milky Way.
        </p>
        <Image
          image={obtenirImage(images, "voieLacteeOrion")}
          langue={page.langue}
        />
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">The hunter hunt</h2>
        <p>
          Although visible from both hemispheres, the Orion constellation (the «
          hunter ») is difficult to photograph. In Europe, it is low in the sky
          and it can only be seen in winter when bad weather is not present. The
          Canary Islands could have been an interesting destination to try the
          cliché because the constellation is very high in the sky, much higher
          than Europe. But there too, the weather is too uncertain. Therefore I
          had to look to the southern hemisphere. But unfortunately,{" "}
          <strong>
            the southern summer is often synonymous of rainy season
          </strong>{" "}
          in deserts easily accessible from France. {""}
        </p>
        <p>
          After watching the weather in world during two winters, {""}
          <strong>southern Atacama in Chile was chosen:</strong> unlike the
          north of this region or unlike Namibia for example, the chances of
          having clear nights at this time of year are high. So we left during
          the new Moon of December 2019: {""}
          <strong>
            at this month of the year, Orion is at its highest in the sky during
            all the night
          </strong>
          . This made possible to photograph the constellation for 6 hours a
          night. In comparison, the months of November or January allow only 5
          hours a night. So we celebrated Christmas under the stars…
        </p>
        <Image
          image={obtenirImage(images, "setupLaNuit")}
          langue={page.langue}
        />
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">The Hacienda of stars</h2>
        <p>
          Located in the southern part of the Atacama Desert, we have been
          warmly welcomed at the Hacienda des stars, located approximately 2
          hours east of the town of Ovalle. {""}
          <strong>A completely isolated place,</strong> with an extraordinary
          starry sky. But above all it is a real oasis of calm and serenity
          since no road lead there: only a very bumpy path and only passable by
          4x4 allows the access.
        </p>
        <Image
          image={obtenirImage(images, "haciendaDesEtoiles")}
          langue={page.langue}
          afficherTitre={false}
        />
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">Equipment</h2>
        <p>
          The idea was to take the shot in only one trip with a good definition
          and maximum exposure time for {""}
          <strong>a moderate cost:</strong>
        </p>
        <ul>
          <li>
            In order to cover a maximum of field per tile, {""}
            <strong>a 24x36mm sensor</strong> was required. My good old EOS 6D
            Mark I was therefore ideal…
          </li>
          <li>
            Due to the few nights available on site (11 nights and risk of
            overcast weather) and to maximize the total exposure time, I bought{" "}
            {""}
            <strong>a second used Canon 6D Mark I</strong>. It was Astrodon
            modded by the company EOS for Astro. During capture, one of the two
            DSLR was computer controlled, while the other was in “slave” mode,
            driven with an intervalometer.
          </li>
          <li>
            On the optical side, the choice fell on two lenses {""}
            <strong>Canon 300 mm F4 L non-stabilized</strong>. They are
            well-known in astrophotography but today they are shunned because
            they are old and non-stabilized. I found mine on Ebay in Japan where
            I found models in absolutely mint condition despite they were aged
            of 25 years (!).
          </li>
          <li>
            To avoid the unsightly egrets on the shining stars, the two lenses{" "}
            {""}
            <strong>were diaphragm from the front at F5</strong> (see my ebook).
          </li>
          <li>
            The individual exposures were 3 minutes long, so autoguiding was
            necessary despite a quality equatorial mount. Because of a fairly
            large sampling, a ZWO Mini Guider 30 mm F4 and an Atik GP camera
            were sufficient.
          </li>
        </ul>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "doubleSetup1")}
            langue={page.langue}
            afficherTitre={false}
          />
          <Image
            image={obtenirImage(images, "doubleSetup2")}
            langue={page.langue}
            afficherTitre={false}
          />
        </div>
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">Software</h2>
        <p>
          The mosaic was programmed using the Sequence Generator Pro software.
          During acquisitions, {""}
          <strong>each tile was framed by computer</strong> thanks to an
          astrometric reduction. The final assembly of the image was then made
          using the Astro Pixel Processor software.
        </p>
        <Image
          image={obtenirImage(images, "astroPixelProcessorOrion")}
          langue={page.langue}
          afficherTitre={false}
        />
        <Image
          image={obtenirImage(images, "sgpOrion")}
          langue={page.langue}
          afficherTitre={false}
        />
      </section>
    </LayoutGalerieImageInteractive>
  )
}
